body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px) !important;
}

.no-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.disableSave {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/myraidpro-regular.woff') format('woff');
}

@font-face {
    font-family: 'Flash on Saturday Night Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Flash on Saturday Night Regular'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/flash-on-saturday-night-regular.otf') format('opentype');
}

@font-face {
    font-family: 'Beautiful Roses';
    font-style: normal;
    font-weight: normal;
    src: local('Beautiful Roses'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/beautiful-roses.ttf') format('truetype');
}

@font-face {
    font-family: 'Tuesday Night';
    font-style: normal;
    font-weight: normal;
    src: local('Tuesday Night'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/TuesdayNight-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Baron Neue';
    font-style: normal;
    font-weight: normal;
    src: local('Baron Neue'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Baron_Neue.otf') format('opentype');
}

@font-face {
    font-family: 'Nobile Bold';
    font-style: normal;
    font-weight: bold;
    src: local('Nobile Bold'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Nobile Bold Italic';
    font-style: italic;
    font-weight: bold;
    src: local('Nobile Bold Italic'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nobile Italic';
    font-style: italic;
    font-weight: normal;
    src: local('Nobile Italic'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nobile Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Nobile Medium'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Nobile Medium Italic';
    font-style: italic;
    font-weight: 500;
    src: local('Nobile Medium Italic'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nobile Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Nobile Regular'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Nobile-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Amostely Signature';
    font-style: normal;
    font-weight: normal;
    src: local('Amostely Signature'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/AmostelySignature.ttf') format('truetype');
}

@font-face {
    font-family: 'Beautiful Scarlet';
    font-style: normal;
    font-weight: normal;
    src: local('Beautiful Scarlet'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Beautiful-Scarlet.woff') format('woff');
}

@font-face {
    font-family: 'Blaimim';
    font-style: normal;
    font-weight: normal;
    src: local('Blaimim'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Blaimim.ttf') format('truetype');
}

@font-face {
    font-family: 'Adobe Jenson Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Jenson Pro Regular'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Adobe_Jenson_Pro_Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Italic'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Oswald Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Oswald Regular'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins'), url('https://d3cn8z1d1ca676.cloudfront.net/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

.link-without-underline {
    text-decoration: none;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}

.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}

.top-right {
    position: absolute;
    top: 8px;
    right: 16px;
}

.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.image-wrapper {
    display: inline-block;
    width: auto;
    position: relative;
    height: 100%;
}

.image-wrapper:after {
    content: '5dee41fae9e0df6bd1e752e2';
    font-size: 18px;
    display: block;
    color: #fff;
    position: absolute;
    z-index: 999;
    bottom: 0px;
}


/* CSS for Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 6px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

button.close:before {
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
}

@media (min-width: 320px) and (max-width: 991px) {

    .modal-content {
        border: none !important;
    }

    .media-popup .modal {
        height: var(--app-height) !important;
    }

    .styled-link li, .styled-link li > a {
        margin-bottom: 5px;
        font-size: 18px;
    }
}
