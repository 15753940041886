.home-posts {
    font-family: 'Avenir';
    line-height: 1.5em;
    vertical-align: middle;
}

.home-posts span {
    font-size: 1.5em;
}

.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    background-color: white;
}

.demo-editor {
    height: 600px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.a-nav-link:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
}

.a-nav-link {
    text-decoration: none;
    color: #fff;
}

.img-div {
    height: 150px;
    min-width: 150px;
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.payment_card_radio input ~ label {
    position: relative;
}

.payment_card_radio input ~ label .fa-check {
    display: none;
}

.payment_card_radio input:checked ~ label .fa-check {
    display: block;
}

.payment_card_radio input:checked ~ label .fa-check {
    position: absolute;
    top: 0;
    right: 35px;
    font-size: 25px;
    bottom: 0;
    display: flex;
    align-items: center;
}

.dragBox {
    width: 250px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-weight: bold;
    line-height: 95px;
    color: #999;
    border: 2px dashed #ccc;
    display: inline-block;
    transition: transform 0.3s;
}

.dragBox input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
}

.draging {
    transform: scale(1.1);
}
.grecaptcha-badge {
    visibility: hidden;
    transition: linear opacity 15s;
}
