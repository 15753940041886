.remove-link:hover {
    text-decoration: none !important;
}

@media (max-width: 767px) { 
    .input-name {
        margin-right: 0px !important;
        width: 100% !important;
    }
    .btn-mg { 
        width: 194px;
    }
    .center-button {
        text-align: center !important;
        padding-left: 10px !important;

    }
}

@media (max-width: 451px) {
    .user-text {
        margin-left: 24px !important;
    }
    .btn-mg {
        width: 248px !important;
    }
}

.absolute-btn-mobile{
    transform: translateY(25px);   
}

@media(max-width:576px) {
    .absolute-btn-mobile {
        position: absolute;
        right: 10px;
        transform: translateY(-60px);   
    }    
}

.list-position {
    position: fixed !important;
    right: 0%;
    bottom: 0%;
    z-index: 1000;
    text-align: center;
    text-decoration: none;
    width: 100% !important;
    border-top: 1px solid #e9ecef !important;
    background-color: #fff;
}

.over {
    transform: scale(1.01);
    border: 1px dashed #eee;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.btn-create {
    position: sticky !important;
    bottom: -16px;
    margin-bottom: -15px; 
    width: calc(100% + 30px);
    margin-left: -16px;
}

.disable-list {
    pointer-events: none;
    opacity: 0.5;
}

@media(max-width:345px){
    .custom-responsive-btns {
        display:flex;
        flex-direction:column;
    }
    .custom-responsive-btns button {
        width:calc(100% - 20px);
        margin:0px 10px 5px 15px;
    }
}

.disable-list {
    pointer-events: none;
    opacity: 0.5;
}

@media (max-width:575px) {
    .list-width {
        width:95%;
    }
}
